.App {
  min-height: 100%;
  box-sizing: border-box;
  position: relative;
}

.App input {
  background: none;
  outline: none;
  border: none;
  color: #40454e;
  border-bottom: #2240454e solid 1px;
  transition: all 200ms;
}

.App input:hover,
.App input:focus {
  border-bottom: #40454e solid 1px;
}

.App input:read-only {
  border-bottom: #0000 solid 1px;
}

.App input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px whitesmoke inset;
  -webkit-text-fill-color: #40454e;
}
