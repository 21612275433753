.root {
  animation-duration: 1s;
  animation-name: fade_in;
  animation-delay: 500ms;
  opacity: 0;
  font-size: 32px;
  padding: 30px;
}

@keyframes fade_in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}