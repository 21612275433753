html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
  background-image: url('./bg.webp');
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html::after {
  content: attr(bg-text);
  position: fixed;
  top: 30px;
  left: 30px;
  text-align: center;
  font-size: 14px;
  z-index: -1;
  font-weight: bold;
  transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  transform-origin: 50% 50%;
  pointer-events: none;
  padding: 0px 45px;
  opacity: 0.5;
}

html.sad {
  filter: grayscale();
}

html.sad::after {
  color: lightgray;
  background-color: black;
}

html.red::after {
  color: white;
  background-color: red;
}

html.mobile>body .gim_scroll_bar,
html.tablet>body .gim_scroll_bar,
html.mobile .gim_scroll_bar,
html.tablet .gim_scroll_bar,
body::-webkit-scrollbar,
html::-webkit-scrollbar,
#root::-webkit-scrollbar {
  display: none;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

* {
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", sans-serif;
}